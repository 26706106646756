* {
  font-family: "Red Rose";
  animation: fadeInAnimation ease 1.5s;
  background-color: transparent;
  color: #ffffff;
}

.sticky {
  position: sticky;
  top: 0px;
  z-index: 100;

  animation: fadeIn 1.5s;
  transition: 3s;
}
@keyframes fadeInAnimation {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes hm {
  0% {
    opacity: 0.6;
  }
  100% {
    opacity: 1;
  }
}

@keyframes wpp {
  20% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes logo {
  20% {
    opacity: 0.4;
  }
  100% {
    opacity: 1;
  }
}
@keyframes wppp {
  20% {
    opacity: 0.7;
  }
  100% {
    opacity: 1;
  }
}

@keyframes leaves {
  0% {
    transform: scale(1);
    -webkit-transform: scale(1);
  }
  100% {
    transform: scale(1.1);
    -webkit-transform: scale(1.1);
  }
}

button {
  cursor: pointer;
}

button:hover {
  transform: scale(1.1);
  transition: 1.5s;
}

.socials:hover {
  transform: scale(1.2);
  transition: 1.5s;
}

.link {
  text-decoration: none;
  background-color: transparent;
}
.link:hover {
  transform: scale(1.2);
  transition: 1s;
}

a {
  text-decoration: none;
  display: flex;
  align-items: center;
}

.a {
  cursor: pointer;
  text-decoration: none;
  color: black;
}

.awssld__content {
  --content-background-color: #ffffff;
}
.awssld__controls__arrow-right {
  background-color: transparent;
}
.awssld__controls__arrow-left {
  background-color: transparent;
}

.awssld__timer--run {
  transform: translateX(0);
  background-color: #f2a922;
}

.awssld__box {
  background-color: transparent;
}

.awssld--active {
  background-color: transparent;
}

.awssld__bullets {
  gap: 2rem;
  animation: none;
  background-color: transparent;
  display: none;
}
.awssld.awssld--organic-arrows {
  margin-top: 0rem;
}
.initialimg {
  width: 400px;
}
.initbackground {
  display: flex;

  flex-direction: column;
  align-items: center;
  height: 90vh;
  justify-content: center;
  animation: fadeInAnimation ease 1s;
  gap: 4rem;
}
.bemvindo {
  font-size: 42px;

  letter-spacing: 4px;
  font-weight: 500;
}

.headerdiv {
  width: 100%;
  display: flex;
  align-items: center;
  height: 8vh;

  background-color: #140fa6;
}
.divlogo {
  background-color: transparent;
}

.logo {
  width: 120px;
  padding-left: 2rem;
  display: flex;
  align-items: center;
  background-color: transparent;
}

.divlinks {
  display: flex;
  justify-content: center;
  width: 85%;
  background-color: transparent;
}

.links {
  font-size: 15px;

  margin: 2rem;

  letter-spacing: 3px;
  background-color: transparent;

  font-weight: bold;
  color: white;
}

.divgifh {
  display: flex;
  justify-content: center;
  height: 100%;
}
.divhm {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  background-color: #140fa6;

  flex-direction: column;
}

.titlehm {
  display: flex;
  justify-content: center;
  padding-top: 4rem;
  background-color: #140fa6;
  flex-direction: column;
  align-items: center;
}
.titlehmh2 {
  background-color: transparent;
  color: white;
  font-size: 100px;
  font-weight: 400;

  margin-top: 0;
  margin-bottom: 0;
}
.subtexthm {
  color: #2104d9;
  background-color: #140fa6;
  color: white;
  font-weight: 200;
  font-size: 55px;
  letter-spacing: 5px;
  text-align: left;

  margin-top: 0;
  margin-bottom: 0;
  padding-top: 2rem;
  align-items: center;
}

.arrow {
  width: 30px;
}
.hmimg {
  width: 140px;
  background-color: transparent;
  margin-top: 4rem;
  margin-bottom: 4rem;
}

.btnhm {
  width: 290px;
  height: 6vh;
  border-radius: 8px;
  font-size: 16px;
  color: #2104d9;
  background-color: #f2a922;
  font-weight: 500;
  border: none;

  letter-spacing: 5px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.awssld__controls__arrow-left:before,
.awssld__controls__arrow-left:after,
.awssld__controls__arrow-right:before,
.awssld__controls__arrow-right:after {
  background-color: #140fa6;
}

.title {
  font-size: 78px;
  font-weight: 600;
  letter-spacing: 10px;
  color: #ffffff;

  height: 10vh;
  padding-top: 4rem;
}

.divtitle {
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  background-color: #140fa6;
}

.divsubtitle {
  display: flex;
  justify-content: center;
  height: 100%;
  background-color: #140fa6;
  padding-top: 3rem;
  padding-bottom: 3rem;
}
.divsub {
  display: flex;
  justify-content: center;
  width: 100%;

  height: 100%;
  padding-top: 2rem;
  padding-bottom: 3rem;
  background-color: #140fa6;
}
.subtext {
  text-align: center;

  font-weight: 400;

  line-height: 12vh;
  width: 1300px;

  font-size: 72px;

  letter-spacing: 2.5px;
  margin-top: 0;
  margin-bottom: 0;
}

.subtitle {
  text-align: center;

  font-weight: 400;

  line-height: 12vh;
  width: 1300px;

  font-size: 72px;
  letter-spacing: 2.5px;
  margin-top: 0;
  margin-bottom: 0;
}
.diveccslogo {
  display: flex;
  justify-content: center;

  height: 100%;
  width: 100%;
}
.divorcamento {
  display: flex;
  justify-content: center;
  height: 100%;

  flex-direction: column;
  align-items: center;
  gap: 1rem;
  padding-bottom: 5rem;
  background-color: #140fa6;
}
.divorcamentos {
  display: flex;
  justify-content: center;
  height: 100%;

  flex-direction: column;
  align-items: center;
  gap: 1rem;
  padding-bottom: 5rem;
  background-color: #140fa6;
  padding-top: 5rem;
}

.orcamentotext {
  font-size: 21px;
  font-weight: bold;
  letter-spacing: 3px;
  text-align: center;
  color: white;
  margin-top: 0;
  margin-bottom: 0;
}

.btnorcamento {
  width: 290px;
  height: 6vh;
  border-radius: 8px;
  font-size: 18px;
  color: #2104d9;
  background-color: #f2a922;
  font-weight: 500;
  border: none;

  letter-spacing: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
}
.awssld__controls__arrow-left:before,
.awssld__controls__arrow-left:after,
.awssld__controls__arrow-right:before,
.awssld__controls__arrow-right:after {
  background-color: #140fa6;
}
.eccslogo {
  width: 300px;
  height: 10vh;
}

.wpp {
  width: 30px;
  animation: wpp 3s infinite;
  background-color: transparent;
}
.logo {
  animation: logo 10s infinite;
}
.btnorcamento {
  animation: wppp 2s infinite;
}

.sectiondiv {
  width: 100%;

  display: flex;

  justify-content: center;

  align-items: center;
  height: 100%;
}

.infoseccs {
  letter-spacing: 2.5px;
  font-weight: 200;
  font-size: 26px;

  width: 1100px;
  text-align: center;
  line-height: 5.5vh;

  margin-top: 0;
}

.btnsection {
  width: 380px;
  height: 6vh;
  border-radius: 8px;

  border: none;
  font-weight: bold;

  letter-spacing: 3px;
  display: flex;
  justify-content: center;
  align-items: center;

  font-size: 18px;
  font-weight: 500;

  color: #2104d9;
  background-color: #f2a922;
}

.divimg {
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 1.5rem;

  justify-content: center;
  width: 100%;
  height: 100%;
  background-color: #140fa6;
}
.gifdiv {
  width: 100%;
  display: flex;
  justify-content: center;
  background-color: black;
}
.gifdivh {
  width: 100%;
  display: flex;
  justify-content: center;
  background-color: black;
}
.video {
  width: 100%;
}
.divbtn {
  padding-bottom: 3rem;
}

.divboard {
  width: 50%;
  height: 50vh;
  background-color: #005ba2;
  margin-left: 30rem;
  margin-top: 15rem;
  border-radius: 15px;
}
.sectionimg {
  width: 100%;
  height: 50vh;
  background-size: cover;
}
.sectionimge {
  width: 100%;
  height: 75vh;
  background-size: cover;
}

.hr {
  width: 1200px;
  height: 0.2vh;
  background-color: #235276;
}

.divhr {
  width: 100%;
  margin-top: 10rem;
}

.h3social {
  display: flex;
  margin-top: 0;
}

.namesocial {
  font-size: 13px;
  font-weight: 100;
  padding-left: 0.8rem;
  letter-spacing: 1.5px;
}
.divfullc {
  height: 100%;
}

.divsocial {
  display: flex;

  align-items: Center;
  font-size: 17px;
  font-weight: 500;
  letter-spacing: 2.5px;

  height: 100%;
  gap: 1rem;
  flex-direction: column;
}

.icons {
  font-size: 28px;
}

.socials {
  font-size: 40px;
}

.divheaderbtn {
  font-size: 21px;
  font-weight: bold;
  display: flex;

  align-items: center;
  letter-spacing: 3px;

  justify-content: Center;
  flex-direction: column;

  gap: 1rem;
  height: 100%;
  padding-bottom: 5rem;
}
.divbtns {
  display: flex;

  align-items: center;

  justify-content: Center;
  flex-direction: column;

  height: 100%;
  padding-bottom: 5rem;
  background-color: #140fa6;
}

.infowpp {
  width: 300px;
  height: 6vh;
  font-size: 18px;
  font-weight: bold;

  color: #2104d9;
  background-color: #f2a922;
  border-radius: 8px;

  border: none;
  font-weight: 500;

  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  letter-spacing: 3px;
}

.btnheader {
  width: 300px;
  font-size: 18px;
  font-weight: 500;

  color: #2104d9;
  background-color: #f2a922;
  height: 6vh;
  border-radius: 8px;

  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  letter-spacing: 3px;
  border: none;
}

.docicon {
  width: 400px;
  padding-top: 2.5rem;
  background-color: transparent;
}

.dociconl {
  width: 250px;
  background-color: transparent;
  padding-top: 2rem;
}

.eccsdiv {
  width: 100%;
  height: 100%;

  align-items: center;
  padding-top: 0;
}

.titleeccs {
  width: 1300px;

  font-size: 72px;
  letter-spacing: 2.5px;
  text-align: center;
  display: flex;
  justify-content: center;
  font-weight: 400;
  margin-top: 0;

  line-height: 12vh;
  letter-spacing: 2.5px;
  margin-bottom: 0;
}

.subtitleeccs {
  text-align: left;
  letter-spacing: 2.5px;
  font-weight: 100;
  font-size: 34px;

  width: 800px;
  line-height: 6vh;
  margin-top: 5rem;
  margin-bottom: 5rem;
  background-color: transparent;
  color: #2104d9;
}

.sobretitle {
  width: 100%;
  justify-content: center;
  display: flex;
  height: 100%;
  padding-top: 3rem;
  padding-bottom: 3rem;
}

.divservico {
  display: flex;
  width: 100%;
  height: 65vh;
}

.servicoicon {
  display: flex;
  width: 100%;
  padding-top: 3rem;
  font-size: 55px;
  color: white;
}

.textservicos {
  display: flex;
  justify-content: center;
  color: white;
  letter-spacing: 1px;
}

.servicoh2 {
  text-align: center;
  font-weight: 400;

  line-height: 12vh;
  width: 1300px;

  font-size: 72px;
  letter-spacing: 2.5px;
  margin-top: 0;
  margin-bottom: 0;
}

.servicos {
  width: 420px;
  font-size: 21px;
  color: white;
  text-align: center;
  padding-left: 2.5rem;
  padding-top: 1rem;
  font-weight: 100;
  letter-spacing: 1px;
}

.servico1 {
  background-color: #005ba2;
  width: 500px;
  height: 43vh;
  border-radius: 20px;
  margin-top: 2rem;
}

@keyframes initialimg {
  20% {
    opacity: 0;
  }
  50% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
    transform: scale(1.1);
  }
}

.imgservico {
  width: 400px;
  height: 38vh;
  display: flex;
  margin-left: 3rem;
  margin-top: 2.8rem;
  border-radius: 20px;
  animation: initialimg 10s infinite;
  cursor: pointer;
}

.carouseltitle {
  display: flex;
  justify-content: center;

  font-weight: 100;
  background-color: #140fa6;
  height: 100%;
  flex-direction: column;
  align-items: center;
  padding-top: 3rem;
  padding-bottom: 3rem;
}

.textcarousel {
  width: 1300px;

  font-size: 72px;
  font-weight: 400;
  line-height: 12vh;
  letter-spacing: 2.5px;
  text-align: center;
  margin-top: 0;
  margin-bottom: 0;
}

.divinputs {
  display: flex;
  flex-direction: column;

  height: 100%;
  align-items: center;
  width: 100%;

  padding-top: 5rem;
}

.infos {
  width: 100%;
  height: 100%;

  gap: 15rem;
  padding-top: 4rem;
  display: flex;
  justify-content: center;

  padding-bottom: 4rem;
}
.infoblue {
  background-color: #140fa6;
}
.infoshome {
  width: 100%;
  height: 100%;

  gap: 15rem;
  padding-top: 4rem;
  display: flex;
  justify-content: center;
  padding-bottom: 4rem;
  background-color: #140fa6;
}

.inputs {
  width: 500px;
  background-color: transparent;
  border: none;

  margin-top: 0rem;
}

textarea:focus,
input:focus {
  box-shadow: 0 0 0 0;
  outline: 0;
}

input::placeholder {
  background-color: transparent;
  font-size: 13px;
  letter-spacing: 4px;
  font-weight: 400;
  color: #ffffff;
}

.nameinputs {
  height: 1vh;
  font-size: 18px;
  margin-top: 0;
  letter-spacing: 2.5px;

  font-weight: 500;
  padding-bottom: 0.2rem;
  width: 500px;
}

.hrinputs {
  width: 500px;
  height: 0.3vh;
  background-color: #f2a922;
  margin-bottom: 2rem;
  margin-top: 0.2rem;
  border: none;
}

.divparceiros {
  display: flex;
  justify-content: space-evenly;
  width: 100%;

  height: 100%;
}

.btncontato {
  width: 175px;
  height: 6vh;
  border-radius: 8px;
  border: none;
  font-size: 18px;
  font-weight: bold;
  letter-spacing: 5px;
  margin-top: 2rem;
  border: none;

  font-weight: 500;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #2104d9;
  background-color: #f2a922;
}

.titlecontato {
  font-size: 50px;

  letter-spacing: 5px;
  font-weight: 500;
  background-color: transparent;
}
.contatodivform {
  display: flex;
  justify-content: center;

  flex-direction: column;
  align-items: center;
  padding-top: 4rem;
  background-color: #140fa6;
}
.divform {
  background-color: #140fa6;
}

.subtitlecontato {
  font-size: 20px;

  letter-spacing: 3px;

  font-weight: 500;
  background-color: transparent;
}

.divtitleservico {
  display: flex;
  justify-content: center;
  padding-top: 2rem;

  padding-bottom: 3rem;
  align-items: center;
  background-color: #140fa6;
}

.servicosection {
  color: white;

  background-color: #140fa6;
  height: 100%;
}

.contatosdiv {
  align-items: center;
  width: 100%;
  font-size: 14px;

  background-color: #ffffff;
  height: 100%;

  display: flex;
  flex-direction: column;
  padding-top: 3rem;
  padding-bottom: 3rem;
}

.produtosdiv {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  font-size: 14px;

  height: 100%;
  background-color: #140fa6;
  padding-top: 3rem;
  padding-bottom: 3rem;
}

.entregadiv {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  font-size: 14px;

  background-color: #ffffff;
  height: 100%;
  padding-top: 3rem;
  padding-bottom: 3rem;
}

.cadastrodiv {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  font-size: 14px;

  height: 100%;

  background-color: #140fa6;
  padding-top: 3rem;
  padding-bottom: 3rem;
}

.acompanhamentodiv {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  font-size: 14px;

  background-color: #ffffff;
  height: 100%;
  padding-top: 3rem;
  padding-bottom: 3rem;
}
.servicodiv {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  font-size: 14px;

  height: 100%;

  background-color: #140fa6;
  padding-top: 3rem;
  padding-bottom: 3rem;
}

.reacticons {
  font-size: 45px;
}

.contatotitle {
  letter-spacing: 7px;
  font-size: 42px;

  font-weight: 400;

  background-color: transparent;
  color: #140fa6;
  margin-top: 0;
  margin-bottom: 0.5rem;
  display: flex;
  justify-content: center;
}

.produtostitle {
  letter-spacing: 7px;
  font-size: 42px;

  font-weight: 400;

  background-color: transparent;
  margin-top: 0;
  margin-bottom: 0;
  margin-bottom: 0.5rem;
}

.cadastrotitle {
  letter-spacing: 7px;
  font-size: 42px;

  font-weight: 400;

  background-color: transparent;
  color: #ffffff;
  margin-top: 0;
  margin-bottom: 0.5rem;
}

.entregatitle {
  letter-spacing: 7px;
  font-size: 42px;

  font-weight: 400;

  background-color: transparent;
  color: #140fa6;
  margin-top: 0;
  margin-bottom: 0.5rem;
}

.acompanhartitle {
  letter-spacing: 7px;
  font-size: 42px;

  font-weight: 400;
  background-color: transparent;

  color: #140fa6;
  margin-top: 0;
  margin-bottom: 0.5rem;
}
.servicotitle {
  letter-spacing: 7px;
  font-size: 42px;

  font-weight: 400;
  background-color: transparent;

  color: #ffffff;
  margin-top: 0;
  margin-bottom: 0.5rem;
}

.subtitleparceiros {
  font-size: 23px;
  letter-spacing: 2.5px;
  font-weight: 400;
  width: 670px;
  text-align: center;

  background-color: transparent;
  color: #ffffff;
  line-height: 3.5vh;
  margin-top: 0;
  margin-bottom: 0;
}
.subtitleparceiroscadastro {
  font-size: 23px;
  letter-spacing: 2.5px;
  font-weight: 400;
  width: 670px;
  text-align: center;
  margin-top: 0;
  margin-bottom: 0;

  background-color: transparent;
  color: #ffffff;
  line-height: 3.5vh;
}
.subtitleparceirosentrega {
  font-size: 23x;
  letter-spacing: 2.5px;
  font-weight: 400;
  margin-top: 0;
  margin-bottom: 0;
  width: 670px;
  text-align: center;

  background-color: transparent;
  color: #140fa6;
  line-height: 3.5vh;
}
.subtitleparceiros1 {
  font-size: 23x;
  letter-spacing: 2.5px;
  font-weight: 400;
  width: 670px;
  text-align: center;
  margin-top: 0;
  margin-bottom: 0;
  background-color: transparent;
  color: #140fa6;
  line-height: 3.5vh;
}

.iconstext {
  font-size: 42px;
  letter-spacing: 2.5px;
  margin-top: 0;
  animation: none;
  margin-top: 2rem;

  font-weight: 300;
  background-color: transparent;
  color: #2104d9;
  text-align: center;
}

.logoeccs {
  width: 250px;
  margin-top: 3rem;
}

.awssld {
  height: 50vh;
  color: white;
}
.direitos {
  display: flex;
  justify-content: center;
  font-size: 12px;
  letter-spacing: 2.5px;
  height: 2vh;
  padding-bottom: 0.2rem;
  background-color: #140fa6;
}
.divcarousel {
  display: flex;
  flex-direction: column;

  align-items: center;
  background-color: transparent;
}

.h3carousel {
  gap: 2rem;

  animation: none;
  margin-top: 0;
  background-color: transparent;
}

@media screen and (max-width: 1700px) {
  .servico1 {
    width: 420px;
    height: 38vh;
  }

  .servicos {
    font-size: 17px;
    padding-left: 0.5rem;
    width: 400px;
  }

  .subtitleeccs {
    width: 1050px;
  }
}

@media screen and (max-width: 1600px) {
}

@media screen and (max-width: 1500px) {
  .hr {
    width: 1000px;
  }

  .divparceiros {
    flex-direction: column;
    display: flex;

    justify-content: center;

    height: 100%;
  }
  .divorcamentos {
    padding-top: 1.5rem;
  }
  .textcarousel {
    font-size: 65px;
    width: 1100px;
    line-height: 9vh;
  }
  .titleeccs {
    font-size: 65px;
    width: 1000px;
    line-height: 9vh;
  }
  .subtext {
    font-size: 65px;
    width: 1100px;
    line-height: 9vh;
  }
  .subtitle {
    font-size: 65px;
    width: 1100px;
    line-height: 9vh;
  }
  .servicoh2 {
    font-size: 65px;
    width: 1100px;
    line-height: 9vh;
  }
  .infoseccs {
    font-size: 32px;
    width: 1000px;
    line-height: 6vh;
  }
  .cadastrodiv {
    background-color: #ffffff;
  }
  .cadastrotitle {
    color: #140fa6;
  }
  .entregadiv {
    background-color: #140fa6;
  }
  .entregatitle {
    color: #ffffff;
  }
  .subtitleparceiroscadastro {
    color: #140fa6;
  }
  .subtitleparceirosentrega {
    color: #ffffff;
  }
}

@media screen and (max-width: 1400px) {
  .title {
    font-size: 30px;
  }

  .servico1 {
    height: 47vh;
  }

  .h3social {
    gap: 0rem;
  }

  .infodiv {
    margin-top: 4rem;
  }
}

@media screen and (max-width: 1300px) {
  .hr {
    width: 1000px;
  }

  .eccsimg {
    height: 35vh;
  }

  .servico1 {
    width: 550px;
    height: 37vh;
  }
  .title {
    font-size: 28px;
  }

  .servicos {
    width: 80%;
    padding-left: 3rem;
  }

  .divservico {
    justify-content: center;
    align-items: center;
  }
  .textcarousel {
    font-size: 50px;
    width: 800px;
    line-height: 7vh;
  }
  .titleeccs {
    font-size: 50px;
    width: 800px;
    line-height: 7vh;
  }
  .subtext {
    font-size: 50px;
    width: 800px;
    line-height: 7vh;
  }
  .subtitle {
    font-size: 50px;
    width: 800px;
    line-height: 7vh;
  }
  .servicoh2 {
    font-size: 50px;
    width: 800px;
    line-height: 7vh;
  }
  .infoseccs {
    font-size: 32px;
    width: 900px;
    line-height: 6vh;
  }
}

@media screen and (max-width: 1200px) {
  .sectionimg {
    height: 40vh;
  }

  .sectionimge {
    height: 40vh;
  }
  .title {
    font-size: 26px;
  }

  .subtitle {
    width: 900px;
  }
  .infoseccs {
    width: 900px;
  }
  .subtext {
    width: 800px;
  }
  .infos {
    flex-direction: column;
    gap: 3rem;
    padding-top: 2.5rem;
  }
  .infoshome {
    flex-direction: column;
    gap: 4rem;
  }

  .hr {
    width: 900px;
  }

  .title {
    letter-spacing: 7px;
  }

  .titleeccs {
    width: 900px;
  }
  .textcarousel {
    width: 900px;
  }
  .servicoh2 {
    width: 900px;
  }
  .subtitleeccs {
    width: 800px;
  }
}

@media screen and (max-width: 1100px) {
  .hr {
    width: 900px;
  }

  .bemvindo {
    font-size: 30px;
  }
  .initialimg {
    width: 300px;
  }

  .title {
    font-size: 24px;
  }
  .infoseccs {
    font-size: 28px;
    width: 800px;
    line-height: 5vh;
  }
}

@media screen and (max-width: 1000px) {
  .hr {
    width: 800px;
  }

  .iconstext {
    font-size: 32px;
  }
  .docicon {
    width: 400px;
    padding-top: 2rem;
  }
  .subtitleeccs {
    font-size: 18px;
    letter-spacing: 2px;
  }

  .logo {
    display: none;
  }

  .titleeccs {
    width: 700px;
  }
  .title {
    font-size: 20px;
  }
  .textcarousel {
    font-size: 40px;
    width: 800px;
    line-height: 6vh;
  }
  .titleeccs {
    font-size: 40px;
    width: 800px;
    line-height: 6vh;
  }
  .subtext {
    font-size: 40px;
    width: 800px;
    line-height: 6vh;
  }
  .subtitle {
    font-size: 40px;
    width: 800px;
    line-height: 6vh;
  }
  .servicoh2 {
    font-size: 40px;
    width: 800px;
    line-height: 6vh;
  }

  .divlinks {
    width: 100%;
  }
  .headerdiv {
    padding-top: 0;
  }
  .contatotitle {
    font-size: 42px;
  }
  .entregatitle {
    font-size: 42px;
  }
  .produtostitle {
    font-size: 42px;
  }
  .cadastrotitle {
    font-size: 42px;
  }
  .acompanhartitle {
    font-size: 42px;
  }
  .servicotitle {
    font-size: 42px;
  }
}

@media screen and (max-width: 900px) {
  .subtitle {
    width: 600px;
  }
  .title {
    letter-spacing: 5px;
  }

  .infoseccs {
    width: 700px;
    font-size: 24px;
    line-height: 4vh;
  }
  .subtext {
    width: 600px;
  }
  .servicoh2 {
    width: 600px;
  }

  .eccsimg {
    height: 27vh;
  }
  .textcarousel {
    width: 600px;
  }

  .hr {
    width: 700px;
  }

  .subtitlecontato {
    text-align: center;
    width: 500px;
    font-size: 18px;
  }
  .subtitleeccs {
    width: 700px;
  }
}

@media screen and (max-width: 800px) {
  .textcarousel {
    font-size: 40px;
  }
  .title {
    font-size: 18px;
  }

  .infodiv {
    flex-direction: column;
    height: 45vh;
  }

  .tel {
    padding-left: 0;
  }

  .mail {
    margin-top: 0;
  }

  .titleeccs {
    width: 550px;
  }
  .servicoh2 {
    width: 550px;
  }
  .textcarousel {
    width: 550px;
  }

  .subtitleeccs {
    font-size: 19px;
    width: 600px;
  }
  .infoseccs {
    width: 600px;
  }

  .hr {
    width: 600px;
  }
  .subtitleparceiros {
    font-size: 16px;
    width: 500px;
  }
  .subtitleparceiros1 {
    font-size: 16px;
    width: 500px;
  }
  .subtitleparceiroscadastro {
    font-size: 16px;
    width: 500px;
  }
  .subtitleparceirosentrega {
    font-size: 16px;
    width: 500px;
  }
}

@media screen and (max-width: 700px) {
  .title {
    font-size: 16px;
    letter-spacing: 3px;
  }

  .hr {
    width: 500px;
  }

  .subtitle {
    width: 500px;
  }
  .infoseccs {
    width: 500px;
    font-size: 21px;
  }
  .subtext {
    width: 500px;
  }
  .subtitleeccs {
    width: 520px;
  }

  .servico1 {
    width: 400px;
    height: 40vh;
  }

  .eccsimg {
    height: 24vh;
  }

  .servicoh2 {
    font-size: 35px;
  }
  .contatotitle {
    font-size: 32px;
  }
  .entregatitle {
    font-size: 32px;
  }
  .produtostitle {
    font-size: 32px;
  }
  .cadastrotitle {
    font-size: 32px;
  }
  .acompanhartitle {
    font-size: 32px;
  }
  .servicotitle {
    font-size: 32px;
  }
}

@media screen and (max-width: 600px) {
  .iconstext {
    font-size: 20px;
    font-weight: bold;
  }

  .textcarousel {
    font-size: 26px;
    width: 450px;
  }
  .links {
    margin: 1rem;
  }
  .subtitleparceiros {
    font-size: 15px;
    width: 400px;
  }
  .subtitleparceiros1 {
    font-size: 15px;
    width: 400px;
  }
  .subtitleparceiroscadastro {
    font-size: 15px;
    width: 400px;
  }
  .subtitleparceirosentrega {
    font-size: 15px;
    width: 400px;
  }

  .title {
    font-size: 14px;
  }

  .subtitle {
    width: 450px;
    font-size: 26px;
  }
  .infoseccs {
    width: 450px;
    font-size: 26px;
  }

  .hr {
    width: 400px;
  }

  .subtitleeccs {
    width: 350px;
    font-size: 16px;
    letter-spacing: 2px;
  }

  .titleeccs {
    font-size: 28px;
    width: 400px;
  }
  .hrinputs {
    width: 400px;
  }
  .subtext {
    font-size: 26px;
    width: 450px;
  }

  .servicoh2 {
    font-size: 26px;
    width: 450px;
  }

  .nameinputs {
    width: 400px;
  }
  .inputs {
    width: 400px;
  }
  .subtitlecontato {
    width: 400px;
  }
}

@media screen and (max-width: 500px) {
  button:hover {
    transform: none;
    transition: none;
  }

  .divsocial {
    font-weight: bold;
  }

  .title {
    font-weight: 600;
    letter-spacing: 2x;
    font-size: 21px;
  }
  .gifdiv {
    padding-top: 1rem;
    background-color: white;
    padding-bottom: 1rem;
  }
  .docicon {
    width: 270px;
    padding-top: 1.5rem;
  }
  .subtitleparceiros {
    font-size: 16px;
    width: 400px;
    font-weight: 600;
  }
  .subtitleparceiros1 {
    font-size: 16px;
    width: 400px;
    font-weight: 600;
  }
  .subtitleparceiroscadastro {
    font-size: 16px;
    width: 400px;
    font-weight: 600;
  }

  .subtitleparceirosentrega {
    font-size: 16px;
    width: 400px;
    font-weight: 600;
  }

  .sectionimg {
    height: 30vh;
    width: 100%;
    margin-top: 2rem;
    margin-bottom: 2rem;
  }
  .divtitleservico {
    padding-top: 2rem;
  }
  .divsub {
    padding-top: 2rem;
  }

  .eccsimages {
    flex-direction: column;
  }
  .eccsimg {
    width: 100%;
    height: 30vh;
  }
  .eccsimg1 {
    height: 30vh;
    width: 100%;
  }

  .links {
    margin: 0.8rem;
    font-weight: 500;
  }
  .link:hover {
    transform: none;
    transition: none;
  }

  .infowpp {
    width: 260px;
  }
  .contatotitle {
    font-size: 32px;
  }
  .produtostitle {
    font-size: 32px;
  }
  .cadastrotitle {
    font-size: 32px;
  }
  .entregatitle {
    font-size: 32px;
  }
  .acompanhartitle {
    font-size: 32px;
  }
  .subtitle {
    width: 420px;
    letter-spacing: 0.5px;
    font-size: 20px;

    line-height: 4vh;
    font-weight: 400;
  }

  .infoseccs {
    font-size: 18px;
    line-height: 4vh;

    letter-spacing: 0.5px;
    font-weight: 100;
    width: 400px;
  }
  .infowpp {
    width: 290px;
  }
  .subtext {
    font-size: 32px;
    width: 370px;
    line-height: 6vh;
    font-weight: 400;
    letter-spacing: 1px;
  }
  .subtitleeccs {
    font-size: 21px;
    line-height: 4vh;

    letter-spacing: 0.5px;

    width: 90%;
    margin-bottom: 4rem;
    margin-top: 3rem;
  }
  .subtitle {
    font-size: 32px;
    width: 370px;
    line-height: 6vh;
    font-weight: 400;
    letter-spacing: 1px;
  }

  .eccslogo {
    width: 220px;
    height: 11vh;
  }
  .contatodivform {
    padding-top: 2rem;
  }

  .btncontato {
    width: 180px;
  }

  .iconstext {
    font-size: 18px;
    letter-spacing: 1px;
  }

  .hr {
    width: 350px;
  }

  .titleeccs {
    font-size: 32px;
    width: 370px;
    line-height: 6vh;
    font-weight: 400;
    letter-spacing: 1px;
  }

  .servico1 {
    width: 360px;
    height: 42vh;
  }

  .infodiv {
    height: 54vh;
  }

  .textcarousel {
    font-size: 32px;
    width: 370px;
    line-height: 6vh;
    font-weight: 400;
    letter-spacing: 1px;
  }

  .btnsection {
    width: 365px;
    font-size: 16px;
  }
  .btnheader {
    font-size: 16px;
    width: 290px;
    font-weight: 500;
  }

  .servicoh2 {
    font-size: 32px;
    width: 370px;
    line-height: 6vh;
    font-weight: 400;
    letter-spacing: 1px;
  }

  .textservicos {
    font-size: 19px;
  }

  .servicoicon {
    font-size: 35px;
  }

  .servicos {
    padding-left: 1.3rem;
    width: 320px;
    font-size: 18px;
    letter-spacing: 1.5px;
  }

  .subtitlecontato {
    font-size: 17px;
    width: 360px;

    letter-spacing: 1px;
    font-weight: 400;
    line-height: 3vh;
  }

  .divhr {
    margin-top: 6rem;
  }

  .titlecontato {
    font-size: 36px;
  }

  .logo {
    width: 120px;
  }
  .divinputs {
    padding-top: 3.5rem;
  }

  .initialimg {
    width: 210px;
  }
  .bemvindo {
    font-size: 25px;
    font-weight: 700;
  }
  .divheaderbtn {
    font-size: 19px;
  }
  .headerdiv {
    height: 7vh;
  }
  .btnorcamento {
    width: 275px;
  }
  .servicosection {
    padding-bottom: 0rem;
  }
  .divorcamentos {
    padding-top: 1rem;
  }
}

@media screen and (max-width: 430px) and (max-height: 932px) {
  .subtitleeccs {
    font-size: 20px;
    line-height: 3.5vh;
  }

  .links {
    font-size: 13px;
    font-weight: bold;
  }
  .bemvindo {
    font-size: 23px;
  }
  .orcamentotext {
    font-size: 19px;

    letter-spacing: 1.5px;
  }
  .btnsection {
    width: 360px;
  }

  .title {
    font-size: 20px;
    font-weight: 800;
    letter-spacing: 2px;
  }
  .subtitle {
    width: 350px;
  }

  .sectionimge {
    height: 25vh;
  }
  .infoseccs {
    font-size: 19px;
    width: 410px;
  }
  .subtext {
    width: 350px;
  }

  .servico1 {
    height: 39vh;
  }

  .servicos {
    font-size: 18px;
  }
  .hrinputs {
    width: 300px;
  }
  .nameinputs {
    width: 300px;
  }
  .inputs {
    width: 300px;
  }
  .subtitleparceiros {
    font-size: 15px;
    width: 350px;
    letter-spacing: 1px;
  }
  .subtitleparceiros1 {
    font-size: 15px;
    width: 350px;
    letter-spacing: 1px;
  }
  .subtitleparceiroscadastro {
    font-size: 15px;
    width: 350px;
    letter-spacing: 1px;
  }
  .subtitleparceirosentrega {
    font-size: 15px;
    width: 350px;
    letter-spacing: 1px;
  }
}

@media screen and (max-width: 414px) {
  .logoeccs {
    margin-top: 1rem;
  }
  .iconstext {
    font-size: 16px;
  }
  .bemvindo {
    font-size: 22px;
  }

  .links {
    margin: 0.5rem;
  }

  .infodiv {
    height: 55vh;
  }
  .subtitleeccs {
    font-size: 18px;
    line-height: 4vh;
  }

  .servicos {
    font-size: 17px;
  }
  .eccslogo {
    width: 180px;
    height: 8vh;
  }

  .infoseccs {
    width: 390px;
    font-size: 18px;
  }
  .btnsection {
    width: 340px;
    letter-spacing: 2px;
  }
}

@media screen and (max-width: 414px) and (max-height: 736px) {
  .servico1 {
    height: 47vh;
  }

  .infodiv {
    height: 60vh;
  }
}

@media screen and (max-width: 412px) {
  .servico1 {
    height: 39vh;
  }
}
@media screen and (max-width: 393px) {
  .infoseccs {
    width: 360px;
    font-size: 16px;
  }
}

@media screen and (max-width: 390px) {
  .titleeccs {
    width: 350px;
    font-size: 30px;
  }
  .textcarousel {
    width: 350px;
    font-size: 30px;
  }
  .servicoh2 {
    width: 350px;
    font-size: 30px;
  }
  .infoseccs {
    width: 360px;
    font-size: 16px;
  }

  .subtitleeccs {
    width: 360px;
    font-size: 17px;
  }
  .divsocial {
    font-size: 16px;
  }
  .bemvindo {
    letter-spacing: 2px;
    font-size: 23px;
  }

  .subtitle {
    width: 350px;
    font-size: 30px;
  }

  .subtext {
    width: 350px;
    font-size: 30px;
  }
  .title {
    font-size: 18px;
  }

  .subtitlecontato {
    width: 340px;
  }
}

@media screen and (max-width: 384px) {
  .servico1 {
    height: 55vh;
  }

  .infodiv {
    height: 70vh;
  }

  .divheaderbtn {
    gap: 1rem;
    height: 7vh;
  }

  .btnsection {
    height: 7vh;
  }
  .btnheader {
    width: 300px;
    height: 7vh;
  }
  .infowpp {
    height: 7vh;
  }

  .btncontato {
    height: 7vh;
  }
}

@media screen and (max-width: 375px) {
  .btnsection {
    height: 6vh;
    font-size: 14px;
    width: 320px;
  }

  .infodiv {
    height: 60vh;
  }

  .btncontato {
    height: 6vh;
    font-size: 14px;
    width: 145px;
  }
  .divsocial {
    font-size: 15px;
  }
  .btnorcamento {
    font-size: 14px;
    width: 245px;
  }
  .subtitle {
    width: 330px;
    font-size: 24px;
  }
  .titleeccs {
    width: 330px;
    font-size: 24px;
  }
  .textcarousel {
    width: 330px;
    font-size: 24px;
  }
  .servicoh2 {
    width: 330px;
    font-size: 24px;
  }

  .subtext {
    width: 330px;
    font-size: 24px;
  }
  .bemvindo {
    font-size: 21px;
  }

  .infowpp {
    height: 6vh;
    font-size: 14px;
    width: 255px;
  }

  .subtitleeccs {
    width: 310px;
    font-size: 19px;
  }

  .servicos {
    font-size: 16px;
  }

  .infodiv {
    height: 60vh;
  }

  .servico1 {
    height: 39vh;
  }
  .divheaderbtn {
    font-size: 17px;
    gap: 1rem;
  }
  .btnheader {
    width: 260px;
    height: 6vh;
    font-size: 14px;
  }
  .title {
    font-size: 18px;
  }
  .subtitlecontato {
    font-size: 16px;
  }
  .contatotitle {
    font-size: 24px;
  }
  .produtostitle {
    font-size: 24px;
  }
  .cadastrotitle {
    font-size: 24px;
  }
  .entregatitle {
    font-size: 24px;
  }
  .acompanhartitle {
    font-size: 24px;
  }
  .servicotitle {
    font-size: 24px;
  }
  .links {
    font-size: 12px;
    letter-spacing: 1px;
  }
  .subtitleparceiros {
    font-size: 15px;
    width: 320px;
    letter-spacing: 1px;
  }
  .subtitleparceiros1 {
    font-size: 15px;
    width: 320px;
    letter-spacing: 1px;
  }
  .subtitleparceiroscadastro {
    font-size: 15px;
    width: 320px;
    letter-spacing: 1px;
  }
  .subtitleparceirosentrega {
    font-size: 15px;
    width: 320px;
    letter-spacing: 1px;
  }
}

@media screen and (max-width: 375px) and (max-height: 667px) {
  .servico1 {
    height: 49vh;
  }

  .divinfo {
    height: 70vh;
  }
}

@media screen and (max-width: 360px) {
  .subtitleeccs {
    font-size: 18px;
  }

  .bemvindo {
    font-size: 20px;
  }
  .divsocial {
    font-size: 14px;
  }
  .title {
    margin-top: 5rem;
    font-size: 17px;
  }
  .titleeccs {
    width: 300px;
    font-size: 24px;
  }
  .textcarousel {
    width: 300px;
    font-size: 24px;
  }
  .servicoh2 {
    width: 300px;
    font-size: 24px;
  }
  .subtitle {
    width: 300px;
    font-size: 24px;
  }

  .subtext {
    width: 300px;
    font-size: 24px;
  }

  .iconstext {
    font-size: 15px;
  }
  .divheaderbtn {
    gap: 1rem;
  }
  .produtostitle {
    font-size: 24px;
  }
  .cadastrotitle {
    font-size: 24px;
  }
  .contatotitle {
    font-size: 24px;
  }
  .entregatitle {
    font-size: 24px;
  }
  .acompanhartitle {
    font-size: 24px;
  }

  .infodiv {
    height: 72vh;
  }
  .orcamentotext {
    font-size: 15px;
  }

  .servico1 {
    width: 300px;
    height: 47vh;
  }

  .btnheader {
    height: 6vh;
    width: 255px;
  }

  .infowpp {
    height: 6vh;
  }

  .btncontato {
    height: 6vh;
  }

  .btnsection {
    width: 280px;
  }

  .btnsection {
    height: 6vh;
    width: 280px;
    font-size: 13px;
    letter-spacing: 1.5px;
  }

  .servicos {
    width: 280px;
    padding-left: 0.7rem;
  }
  .subtitlecontato {
    width: 300px;
    font-size: 14px;
  }
  .infoseccs {
    font-size: 15px;
    width: 330px;
  }

  .subtitleeccs {
    font-size: 16px;
  }
}

@media screen and (max-width: 360px) and (max-height: 640px) {
  .servico1 {
    height: 47vh;
  }

  .infodiv {
    height: 60vh;
  }
}
@media screen and (max-width: 350px) {
  .subtitleparceiros {
    font-size: 14px;
    width: 280px;
    letter-spacing: 0.5px;
  }
  .subtitleparceiros1 {
    font-size: 14px;
    width: 280px;
    letter-spacing: 0.5px;
  }
  .subtitleparceiroscadastro {
    font-size: 14px;
    width: 280px;
    letter-spacing: 0.5px;
  }
  .subtitleparceirosentrega {
    font-size: 14px;
    width: 280px;
    letter-spacing: 0.5px;
  }
}
@media screen and (max-width: 320px) {
  .title {
    font-size: 15px;
  }

  .servico1 {
    height: 53vh;
  }
  .hrinputs {
    width: 260px;
  }
  .nameinputs {
    width: 260px;
  }
  .inputs {
    width: 260px;
  }
  .hr {
    width: 250px;
    margin-left: 2.1rem;
  }
  .infoseccs {
    letter-spacing: 0px;
    font-size: 14px;
  }
  .divsocial {
    font-size: 13px;
  }
  .btncontato {
    font-size: 13px;
    letter-spacing: 1px;
    width: 120px;
  }
  .bemvindo {
    font-size: 18px;
  }
  .btnorcamento {
    font-size: 13px;
    letter-spacing: 1px;
    width: 185px;
  }

  .servicoh2 {
    font-size: 20px;
    width: 270px;
  }
  .wpp {
    width: 25px;
  }
  .subtitlecontato {
    width: 270px;
  }

  .subtitlecontato {
    width: 270px;
    font-size: 13px;
  }
  .btnheader {
    width: 210px;
    font-size: 13px;
    letter-spacing: 1px;
  }
  .divheaderbtn {
    font-size: 15px;
  }
  .btnsection {
    width: 255px;
    font-size: 13px;
    letter-spacing: 1px;
  }

  .iconstext {
    font-size: 14px;
  }

  .infodiv {
    height: 75vh;
  }

  .btnheader {
  }

  .infowpp {
    width: 210px;
    font-size: 13px;
    letter-spacing: 1px;
  }

  .textcarousel {
    width: 270px;
    font-size: 18px;
  }

  .titleeccs {
    width: 270px;
    font-size: 18px;
  }

  .subtitleeccs {
    width: 270px;
    font-size: 15px;
  }

  .links {
    margin: 0.4rem;
  }

  .infoseccs {
    width: 290px;
  }
  .subtext {
    width: 270px;
    font-size: 18px;
  }
  .subtitle {
    width: 270px;
    font-size: 18px;
  }
  .servicoh2 {
    font-size: 18px;
    width: 270px;
  }

  .tel {
    font-size: 13px;
  }
  .mail {
    font-size: 13px;
  }
}

@media screen and (max-width: 320px) and (max-height: 568px) {
  .servico1 {
    height: 60vh;
  }

  .infowpp {
    width: 270px;
  }
}
